import { Box, Center, Spinner } from '@chakra-ui/react';

const SimpleLoading = () => {
  return (
    <Box
      pos='absolute'
      top='0'
      left='0'
      right='0'
      bottom='0'
      bg='blackAlpha.600'
      borderRadius='md'
      zIndex='3'
    >
      <Center height='100%'>
        <Spinner size='xl' thickness='4px' color='white'></Spinner>
      </Center>
    </Box>
  );
};

export default SimpleLoading;
