import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  VStack,
  useModalContext,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { type ComponentProps, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FileImageResizeButton from './internal/FileImageResizeButton';

type Props = {
  files: File[];
  onSelected: (files: File[]) => void;
  onCancelled: () => void;
};

const resolutions = [
  {
    labelKey: 'original',
    resize: async (files: File[]) => {
      return await files;
    },
  },
  {
    labelKey: 'high-def',
    resize: async (files: File[]) => {
      const lib = await import('@misskey-dev/browser-image-resizer');
      return Promise.all(
        files.map(
          async (file) =>
            new File(
              [
                await lib.readAndCompressImage(file, {
                  maxWidth: 2000,
                  maxHeight: 2000,
                  quality: 0.85,
                }),
              ],
              file.name,
              { type: file.type }
            )
        )
      );
    },
  },
  {
    labelKey: 'medium-def',
    resize: async (files: File[]) => {
      const lib = await import('@misskey-dev/browser-image-resizer');
      return Promise.all(
        files.map(
          async (file) =>
            new File(
              [
                await lib.readAndCompressImage(file, {
                  maxWidth: 1000,
                  maxHeight: 1000,
                  quality: 0.85,
                }),
              ],
              file.name,
              { type: file.type }
            )
        )
      );
    },
  },
] satisfies {
  labelKey: ComponentProps<typeof FileImageResizeButton>['labelKey'];
  resize: (files: File[]) => Promise<File[]>;
}[];

export default function FileImageResizeSelectorModalContent({
  files,
  onSelected,
  onCancelled,
}: Props) {
  const { data, isLoading, error } = useQuery({
    queryKey: ['resize', files.map((f) => f.name).join(',')],
    queryFn: async () => {
      return Promise.all(
        resolutions.map(async (r) => {
          const resized = await r.resize(files);
          return {
            labelKey: r.labelKey,
            size: resized.map((f) => f.size).reduce((a, b) => a + b),
            files: resized,
          };
        })
      );
    },
  });
  const { t } = useTranslation();
  const modalContext = useModalContext();

  useEffect(() => {
    if (error) {
      console.error(error);
      modalContext.onClose();
      onSelected(files);
    }
  }, [error, modalContext, files, onSelected]);

  return (
    <ModalContent>
      <ModalHeader>{t('image-resize.title')}</ModalHeader>
      <ModalCloseButton onClick={onCancelled} />
      <ModalBody pt={0}>
        {isLoading || !data ? (
          <Spinner />
        ) : (
          <VStack gap={4}>
            {data.map(({ labelKey, size, files }) => (
              <FileImageResizeButton
                key={labelKey}
                labelKey={labelKey}
                size={size}
                onClick={() => {
                  onSelected(files);
                  modalContext.onClose();
                }}
              />
            ))}
          </VStack>
        )}
      </ModalBody>

      <ModalFooter>
        <Button
          colorScheme='gray'
          mr={5}
          onClick={() => {
            modalContext.onClose();
            onCancelled();
          }}
        >
          {t('actions.cancel')}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}
