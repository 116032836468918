import { useToast } from '@/utils/atoms/toast';
import { uploadFile } from '@/utils/file/files';
import {
  type FileContentType,
  type FileIdAndUrl,
  type IFile,
  canAcceptType,
  isPdfType,
  isVideoType,
} from '@/utils/file/type';
import useTranslation from '@/utils/i18n/useTranslation';
import { type ChangeEvent, useCallback, useMemo, useState } from 'react';

import { DocumentTypeEnum } from '@/graphql/types';
import { useModal } from '@/utils/hooks/useModal';
import FileImageResizeSelectorModalContent from './FileImageResizeSelectorModal';
import FileUploadButton from './FileUploadButton';
import FileUploadZone from './FileUploadZone';

export interface DocumentProps {
  id?: string;
  fileData?: string;
  file: File;
  size?: number;
  url?: string;
  type?: DocumentTypeEnum;
}

export type AcceptType =
  | 'image'
  | 'video'
  | 'pdf'
  | 'csv'
  | 'doc'
  | 'docx'
  | 'xls'
  | 'xlsx'
  | 'ppt'
  | 'pptx'
  | 'txt'
  | 'link';

export type AcceptTypeData = {
  accept: string;
  acceptStart: string;
  label: string;
};

export type FileUploadProps = {
  showLinkButton?: boolean;
  getFileUploadUrls: (filesContentTypes: FileContentType[]) => Promise<FileIdAndUrl[]>;
  addFile?: (file: DocumentProps) => void;
  addFiles?: (files: IFile[]) => void;
  acceptTypes?: AcceptType[];
  isMultiple?: boolean;
};

export const AcceptTypeDataMap = new Map<AcceptType, AcceptTypeData>([
  ['image', { accept: 'image/*', acceptStart: 'image/', label: 'image' }],
  ['video', { accept: 'video/*', acceptStart: 'video/', label: 'video' }],
  ['pdf', { accept: 'application/pdf', acceptStart: 'application/pdf', label: 'pdf' }],
  ['csv', { accept: 'text/csv', acceptStart: 'text/csv', label: 'csv' }],
  [
    'doc',
    {
      accept: 'application/msword',
      acceptStart: 'application/msword',
      label: 'Word',
    },
  ],
  [
    'docx',
    {
      accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      acceptStart: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      label: 'Word',
    },
  ],
  [
    'xls',
    {
      accept: 'application/vnd.ms-excel',
      acceptStart: 'application/vnd.ms-excel',
      label: 'Excel',
    },
  ],
  [
    'xlsx',
    {
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      acceptStart: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      label: 'Excel',
    },
  ],
  [
    'ppt',
    {
      accept: 'application/vnd.ms-powerpoint',
      acceptStart: 'application/vnd.ms-powerpoint',
      label: 'Powerpoint',
    },
  ],
  [
    'pptx',
    {
      accept: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      acceptStart: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      label: 'Powerpoint',
    },
  ],
  ['txt', { accept: 'text/plain', acceptStart: 'text/plain', label: 'Text' }],
]);

export const useFileUploadHandler = (props: FileUploadProps) => {
  const { toast } = useToast();
  const {
    getFileUploadUrls,
    addFile,
    addFiles,
    acceptTypes = ['image', 'video'],
    isMultiple = false,
  } = props;
  const { t, t_toasts } = useTranslation();

  const [isUploading, setIsUploading] = useState(false);

  const { acceptArray, acceptStartArray, label } = useMemo(() => {
    const acceptArray: string[] = [];
    const acceptStartArray: string[] = [];
    const labelArray: string[] = [];
    acceptTypes.forEach((type) => {
      const data = AcceptTypeDataMap.get(type);
      if (data) {
        acceptArray.push(data.accept);
        acceptStartArray.push(data.acceptStart);
        labelArray.push(data.label);
      }
    });
    const label = labelArray.map((label) => t(label)).join(t('comma'));
    return {
      acceptArray,
      acceptStartArray,
      label,
    };
  }, [acceptTypes, t]);

  const handleFile = useCallback(
    async (id: string, url: string, file: File): Promise<void> => {
      const { type, size } = file;

      try {
        await uploadFile(url, file);

        const reader = new FileReader();

        reader.onload = (e) => {
          if (e.target === null || !addFile) return;
          const result = e.target.result;
          if (result instanceof ArrayBuffer) {
            // ArrayBufferを処理する
            const blob = new Blob([new Uint8Array(result)], {
              type,
            });
            const url = window.URL.createObjectURL(blob);
            addFile({
              id,
              fileData: url,
              file,
              size,
              type: DocumentTypeEnum.Document,
            });
          } else if (typeof result === 'string') {
            addFile({
              id,
              fileData: result,
              file,
              size,
              type: DocumentTypeEnum.Document,
            });
          }
        };

        isVideoType(type) || isPdfType(type)
          ? reader.readAsArrayBuffer(file)
          : reader.readAsDataURL(file);
      } catch (e) {
        throw e;
      }
    },
    [addFile]
  );

  const handleFiles = useCallback(
    async (
      filesData: {
        file: File;
        id: string;
        url: string;
      }[]
    ) => {
      const filesUploadAndReadPromises = filesData.map(({ id, url, file }) => {
        return new Promise<IFile>(async (resolve, reject) => {
          try {
            await uploadFile(url, file);
          } catch (e) {
            reject(e);
            return;
          }

          const reader = new FileReader();

          reader.onload = (e) => {
            if (e.target === null) return reject();

            const result = e.target.result;
            let fileData = '';

            if (result instanceof ArrayBuffer) {
              const blob = new Blob([new Uint8Array(result)], {
                type: file.type,
              });
              fileData = window.URL.createObjectURL(blob);
            } else if (typeof result === 'string') {
              fileData = result;
            }

            resolve({ id, fileData, file });
          };

          reader.onerror = () => {
            reject(new Error('FileReader error'));
          };

          if (isVideoType(file.type) || isPdfType(file.type)) {
            reader.readAsArrayBuffer(file);
          } else {
            reader.readAsDataURL(file);
          }
        });
      });

      try {
        const uploadedFiles = await Promise.all(filesUploadAndReadPromises);

        if (addFiles && uploadedFiles) addFiles(uploadedFiles);
      } catch (_error) {
        toast({
          title: t_toasts('failed.upload-files'),
          status: 'error',
        });
      }
    },
    [addFiles, t_toasts, toast]
  );

  const validateFiles = useCallback(
    (files: File[]) => {
      if (!isMultiple && files.length > 1) {
        toast({
          title: t_toasts('failed.register-one-file'),
          status: 'error',
        });
        return false;
      }

      const filesContentTypes = files.map((file) => file.type);
      if (!filesContentTypes.every((type) => canAcceptType(type, acceptStartArray))) {
        toast({
          title: t_toasts('failed.label-attached-only', { label }),
          status: 'error',
        });
        return false;
      }

      const filesSizes = files.map((file) => file.size);
      if (!filesSizes.every((size) => size < 100 * 1024 * 1024)) {
        toast({
          title: t_toasts('failed.upload-limited'),
          status: 'error',
        });
        return false;
      }

      return true;
    },
    [acceptStartArray, isMultiple, label, t_toasts, toast]
  );

  const { element: resizeModal, open: openResizeModal } = useModal({
    Component: FileImageResizeSelectorModalContent,
    size: { base: 'sm', md: 'sm' },
  });

  const openResizeModalIfSupported = useCallback(
    async (files: File[]): Promise<File[]> => {
      // 全て画像の時だけ対応
      if (files.some((file) => !file.type.startsWith('image/'))) {
        return files;
      }

      return new Promise((resolve) => {
        openResizeModal({
          files: files,
          onSelected: resolve,
          onCancelled: () => resolve([]),
        });
      });
    },
    [openResizeModal]
  );

  const handleFilesUploads = useCallback(
    async (files: File[]) => {
      if (!validateFiles(files)) return;
      try {
        setIsUploading(true);

        const resizedFiles = await openResizeModalIfSupported(files);
        if (resizedFiles.length === 0) {
          return;
        }

        const filesUrls = await getFileUploadUrls(
          resizedFiles.map((file) => ({ contentType: file.type }))
        );

        const filesData = resizedFiles.map((file, index) => ({
          file,
          id: filesUrls[index].id,
          url: filesUrls[index].url,
        }));

        if (addFiles) {
          await handleFiles(filesData);
        } else {
          const file = filesData[0];
          await handleFile(file.id, file.url, file.file);
        }
      } finally {
        setIsUploading(false);
      }
    },
    [
      addFiles,
      getFileUploadUrls,
      handleFile,
      handleFiles,
      validateFiles,
      openResizeModalIfSupported,
    ]
  );

  const handleFilesChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const files = event.currentTarget.files;

      if (!files || files?.length === 0) return;

      handleFilesUploads(Array.from(files));
    },
    [handleFilesUploads]
  );

  const handleDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (event.dataTransfer && event.dataTransfer.files.length) {
        const fileList = event.dataTransfer.files;

        handleFilesUploads(Array.from(fileList));
      }
    },
    [handleFilesUploads]
  );

  return { acceptArray, label, handleFilesChange, handleDrop, isUploading, resizeModal };
};

export { FileUploadButton, FileUploadZone };
