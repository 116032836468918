import {
  useGenerateFileUploadUrlsMutation,
  useGenerateFileUrlMutation,
} from '@/common/graphql/files.generated';
import { useCallback } from 'react';
import { FileContentType, FileIdAndUrl } from './type';

export interface IFileInterface {
  name: string;
  contentType?: string | null;
  fileId?: string | null;
  path?: string;
}

const fileIdAndDataMap = new Map<string, string>([]);

type ReturnType = {
  getFileUploadUrls: (filesContentTypes: FileContentType[]) => Promise<FileIdAndUrl[]>;
  setFileUrlToSrcFiles: <T extends IFileInterface>(files: T[]) => Promise<(T & { src: string })[]>;
  cacheFileSrc: (fileId: string, src: string) => void;
  openLink: (url: string) => void;
};

export const useFiles = (): ReturnType => {
  const [generateFileUploadUrls] = useGenerateFileUploadUrlsMutation();

  const [generateFileUrl] = useGenerateFileUrlMutation();

  const getFileUploadUrls = async (
    filesContentTypes: FileContentType[]
  ): Promise<FileIdAndUrl[]> => {
    const { data } = await generateFileUploadUrls({
      variables: {
        newFilesUploadUrlsInput: filesContentTypes,
      },
    });
    if (!data) throw new Error('アップロード用一時発行URLが発行できませんでした');

    return data.generateFileUploadUrls;
  };

  const setFileUrlToSrcFiles = useCallback(
    async <T extends IFileInterface>(files: T[]) => {
      const localFiles = await Promise.all(
        files.map(async (file) => {
          if (file.fileId) {
            const fileData = fileIdAndDataMap.get(file.fileId);
            if (fileData) {
              return {
                ...file,
                src: fileData,
              };
            }
            const { data } = await generateFileUrl({
              variables: { newFileUrlInput: { path: file.path! } }, // todo fixme
            });
            return { ...file, src: data ? data.generateFileUrl : '' };
          }
          return { ...file, src: '' };
        })
      );
      return localFiles;
    },
    [generateFileUrl]
  );

  const cacheFileSrc = (fileId: string, src: string) => {
    fileIdAndDataMap.set(fileId, src);
  };

  const openLink = (url: string) => {
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('target', '_blank');
    a.setAttribute('download', `${Date.now()}.pdf`);
    a.click();
  };

  return {
    getFileUploadUrls,
    setFileUrlToSrcFiles,
    cacheFileSrc,
    openLink,
  };
};
