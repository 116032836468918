import { Button, Center, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MdImage } from 'react-icons/md';

type Props = {
  labelKey: 'original' | 'high-def' | 'medium-def';
  size: number;
  onClick: () => void;
};

export default function FileImageResizeButton({ labelKey, size, onClick }: Props) {
  const { t } = useTranslation();
  return (
    <Button
      colorScheme='primary'
      variant='outline'
      size='lg'
      onClick={onClick}
      w='full'
      p={4}
      leftIcon={
        <Center boxSize='24px'>
          <MdImage
            size={(() => {
              switch (labelKey) {
                case 'original':
                  return '24px';
                case 'high-def':
                  return '20px';
                case 'medium-def':
                  return '16px';
              }
            })()}
          />
        </Center>
      }
    >
      <Flex w='full' justifyContent='space-between'>
        <Text fontWeight='semibold' fontSize='md'>
          {t(`image-resize.${labelKey}`)}
        </Text>
        <Text fontWeight='normal' fontSize='md'>
          {formatBytes(size)}
        </Text>
      </Flex>
    </Button>
  );
}

const formatBytes = (bytes: number) => {
  return new Intl.NumberFormat('en-En', {
    notation: 'compact',
    style: 'unit',
    unit: 'byte',
    unitDisplay: 'narrow',
    maximumFractionDigits: 0,
  }).format(bytes);
};
